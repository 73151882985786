import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import NotificationService from '@/components/notification-service';
import { SWRConfig } from 'swr';
import { CLIENT_SECRETS } from '@/config/client_secrets';

export const SWRProvider = ({ children }) => {
  return (
    <SWRConfig
      value={{
        fetcher: async url => {
          const res = await FrontendHttpClient.get(url);
          return res.data;
        },
        onError: (error, key) => {
          // eslint-disable-next-line no-console
          console.log(`API Call Failed:`, key);

          // eslint-disable-next-line no-console
          console.log('API Error: ', error);

          // Find resource name in key. It's the one right after "v2".
          // For example "policies" in "/api/v2/policies/internal/grid" or "users" in /v2/users/123
          const resourceName = key?.split('/').find((item, index, arr) => {
            return arr[index - 1] === 'v2';
          });

          // If resource name is found, replace underscores with empty spaces
          // and ignore anything after '"' or '?' (for example "policies?useCache=false" would become "policies")
          const parsedResourceName = resourceName
            ?.replace('_', ' ')
            .split(/["?]/)[0];

          if (CLIENT_SECRETS.SWR_ERROR_TOAST_ENABLED) {
            NotificationService.error(
              `Error loading ${parsedResourceName || 'data'}`
            );
          }
        },
      }}
    >
      {children}
    </SWRConfig>
  );
};
